import React from 'react';
import Main from '../components/Main';
import Layout from '../components/layouts/en';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Main />
  </Layout>
);

export default IndexPage;
